.CoinView {
  overflow: hidden;

  &__coin-item-minimal {
    width: 350px;
    display: flex;
    margin: 15px 15px;
    justify-content: space-between;
    align-items: center;
  }

  &__coin-carousel-container {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-left: 15px;
  }

  &__coin-carousel-container::-webkit-scrollbar {
    display: none;
  }

  &__coin-item-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    min-width: 170px;
    margin: 6px 6px;
    padding: 6px;
    border: 1px solid #7d7d7da1;
    border-radius: 10px;
  }

  &__coin-item-list-active {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    min-width: 170px;
    margin: 6px 6px;
    padding: 6px;
    border: 1px solid #7d7d7da1;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.15);
  }

  &__history-items ul {
    display: flex;
  }

  &__history-items ul > li {
    list-style: none;
    margin-left: 7px;
    font-size: 12px;
    padding: 6px;
    text-align: center;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.18);
    border-radius: 3px;
  }

  &__history-items ul > li:active {
    background: rgba(187, 187, 187, 0.18);
  }

  &__history-items ul > li.li-active {
    background: rgba(187, 187, 187, 0.18);
  }

  &__flex-item {
    display: flex; align-items: center;
  }

  &__flex-left-column {
    display: flex; flex-direction: column; text-align: left; margin-left: 5px;
  }

  &__flex-right-column {
    display: flex; flex-direction: column; text-align: right; margin-right: 5px;
  }

  &__green {
    color: #48a386;
  }
  &__green:before {
    content: '+'
  }
  &__green:after {
    content: '%'
  }
  &__green-portfolio {
    color: #48a386;
  }
  &__green-portfolio:before {
    content: '+'
  }
  &__green-portfolio:after {
    content: "";
  }

  &__red {
    color: #b23d3d;
  }
  &__red:before {
    content: '-'
  }
  &__red:after {
    content: '%'
  }
  &__red-portfolio {
    color: #b13d3e;
  }
  &__red-portfolio:before {
    content: '-'
  }
  &__red-portfolio:after {
    content: '&#8593';
  }
}
