@import '../../node_modules/crypto-icons/styles.css';
@import '../../node_modules/crypto-icons/font.css';

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;
  background: center / contain -webkit-linear-gradient(352deg, rgb(20, 21, 27), rgb(64, 67, 79));
  background: center / contain -moz-linear-gradient(352deg, rgb(20, 21, 27), rgb(64, 67, 79));
  background: center / contain linear-gradient(352deg, rgb(20, 21, 27), rgb(64, 67, 79));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  margin: 0 auto;

  text-align: center;
}

.icon {
  font-size: 32px;
}
