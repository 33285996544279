.Header {
  display: flex;
  position: sticky;
  top: 0;
  background: #3c3f4b29;
  flex-direction: row;
  justify-content: space-between;
  padding: 35px 20px;
  color: white;
}



