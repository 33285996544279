
.app-header {
  display: flex;
  position: sticky;
  top: 0;
  background: #3c3f4b29;
  flex-direction: row;
  justify-content: space-between;
  padding: 35px 20px;
  color: white;
}

.button-try-again {
  color: #ffffff;
  font-size: 18px;
  height: 50px;
  width: 100px;
  margin: 0 auto;
  background: rgba(72, 163, 134, 0);
}

.text_secondary {
  color: #868c91;
  font-size: 14px;
}


.green {
  color: #48a386;
}
.green:before {
  content: '+'
}
.green:after {
  content: '%'
}
.green-portfolio {
  color: #48a386;
}
.green-portfolio:before {
  content: '+'
}
.green-portfolio:after {
  content: "";
}
.red {
  color: #b23d3d;
}
.red:before {
  content: '-'
}
.red:after {
  content: '%'
}
.red-portfolio {
  color: #b13d3e;
}
.red-portfolio:before {
  content: '-'
}
.red-portfolio:after {
  content: '&#8593';
}

//.coin-item {
//  display: flex;
//  flex-direction: column;
//  min-height: 150px;
//  margin: 12px 20px;
//  padding: 10px;
//  background: rgba(255, 255, 255, 0.15);
//  border-radius: 10px;
//}
//.coin-item-row {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//}
//.coin-item-minimal {
//  width: 350px;
//  display: flex;
//  margin: 15px 15px;
//  justify-content: space-between;
//  align-items: center;
//}
//.coin-carousel-container {
//  display: flex;
//  flex-direction: row;
//  white-space: nowrap;
//  overflow-x: scroll;
//  overflow-y: hidden;
//  margin-left: 15px;
//}
//.coin-carousel-container::-webkit-scrollbar {
//  display: none;
//}
//
//.coin-item-list {
//  display: flex;
//  flex-wrap: nowrap;
//  flex-direction: row;
//  align-items: center;
//  justify-content: space-between;
//  height: 40px;
//  min-width: 170px;
//  margin: 6px 6px;
//  padding: 6px;
//  border: 1px solid #7d7d7da1;
//  border-radius: 10px;
//}
//
//.coin-item-list-active {
//  display: flex;
//  flex-wrap: nowrap;
//  flex-direction: row;
//  align-items: center;
//  justify-content: space-between;
//  height: 40px;
//  min-width: 170px;
//  margin: 6px 6px;
//  padding: 6px;
//  border: 1px solid #7d7d7da1;
//  border-radius: 10px;
//  background: rgba(255, 255, 255, 0.15);
//}
//.history-items ul {
//  display: flex;
//}
//.history-items ul > li {
//  list-style: none;
//  margin-left: 7px;
//  font-size: 12px;
//  padding: 6px;
//  text-align: center;
//  color: #ffffff;
//  background: rgba(0, 0, 0, 0.18);
//  border-radius: 3px;
//}
//.history-items ul > li:active {
//  background: rgba(187, 187, 187, 0.18);
//}
//.history-items ul > li.li-active {
//  background: rgba(187, 187, 187, 0.18);
//}
//.paragraph-secondary {
//  color: #868c91;
//}



